<template>
    <main class="swcluster-main" id="swcluster-guide-visualization">
        <!-- page-banner -->
        <div class="page-banner">
            <div class="banner-image">
                <img src="@/assets/images/guide/mainbanner_visualization.jpg" alt="" />
            </div>
        </div>
        <!-- page-header -->
        <div class="page-header sw-divider">
            <div class="header-component">
                <CommonBreadcrumb :show-title="true" />
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body">
            <!-- page-contents -->
            <div class="page-contents">
                <!-- content-section:Amazon SageMaker -->
                <article class="content-major-section section-component">
                    <header class="major-header">
                        <h3 class="title title-en">Amazon QuickSight</h3>
                        <p class="description">Amazon QuickSight를 활용한 시각화 서비스 제공</p>
                    </header>
                    <div class="content-feature">
                        <!-- feature-item -->
                        <div class="feature-item">
                            <div class="feature-image">
                                <img src="@/assets/images/guide/img_feature_2_1.png" alt="" />
                            </div>
                            <div class="feature-content">
                                <p class="text">
                                    QuickSight는 최종 사용자가 데이터 중심 결정을 내릴 수 있도록 고객에게 매주 수백만 개의 대시보드 보기를 지원합니다.
                                </p>
                            </div>
                        </div>
                        <!-- feature-item -->
                        <div class="feature-item">
                            <div class="feature-video">
                                <!--                                <iframe width="535" height="300" src="https://www.youtube.com/embed/2V1bHRLRG-w?autoplay=1&mute=1" />-->
                                <iframe width="535" height="300" src="https://www.youtube.com/embed/2V1bHRLRG-w" />
                                <!--                                <video src="" poster="@/assets/images/guide/img_feature_2_2.png"></video>-->
                                <!--                                <div class="video-actions">-->
                                <!--                                    <button class="btn-action"><i class="icon-play"></i></button>-->
                                <!--                                </div>-->
                            </div>
                        </div>
                    </div>
                </article>
                <!-- content-section:모두가 ML로 혁신 가능하게 지원 -->
                <article class="content-section sw-divider">
                    <div class="section-component">
                        <div class="symbol-list-container card-image-container">
                            <ul class="symbol-list" data-ratio="1x1">
                                <li class="symbol-item">
                                    <div class="symbol-wrap">
                                        <div class="symbol"><img src="@/assets/images/guide/symbol_2_2.svg" alt="" /></div>
                                        <h4 class="symbol-title">ML 인사이트로 <br />고급 분석 수행</h4>
                                        <p class="symbol-content">
                                            기계 학습에 대한 AWS의 전문지식을 활용해 데이터에서 숨겨진 인사이트를 발견하거나, 정확한 예측 및 가정(what-if)
                                            분석을 수행하거나, 이해하기 쉬운 자연어 문장을 대시보드에 추가할 수 있습니다.
                                        </p>
                                    </div>
                                </li>
                                <!-- symbol-item -->
                                <li class="symbol-item">
                                    <div class="symbol-wrap">
                                        <div class="symbol"><img src="@/assets/images/guide/symbol_2_3.svg" alt="" /></div>
                                        <h4 class="symbol-title">애플리케이션 차별화를 위한 <br />분석 기능 임베딩</h4>
                                        <p class="symbol-content">
                                            대화형 시각화 및 대시보드, 정교한 대시보드 작성 또는 자연어 쿼리 기능을 애플리케이션에 쉽게 임베딩시켜 사용자 경험을
                                            차별화하고 새로운 수익 창출 기회를 얻을 수 있습니다.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </article>
                <article class="content-section sw-divider">
                    <div class="section-component">
                        <header class="section-header">
                            <h3 class="title title-sm">모두를 위한 설계</h3>
                        </header>
                        <div class="card-list-container card-image-container">
                            <ul class="card-list" data-column="2" data-ratio="2x1">
                                <!-- card-item -->
                                <li class="card-item">
                                    <div class="card">
                                        <div class="card-image">
                                            <img src="@/assets/images/guide/img_card_2_1.jpg" alt="" />
                                        </div>
                                        <div class="card-body">
                                            <p class="card-text">
                                                조직의 최종 사용자가 자연어로 질문을 하면 답변과 함께 관련된 시각화 자료를 받을 수 있습니다. 그리고 QuickSight
                                                Q는 기계 학습을 사용하여 질문의 의도를 이해하고 데이터를 분석하여 비즈니스 관련 질문에 빠르게 답변을 제공합니다.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <!-- card-item -->
                                <li class="card-item">
                                    <div class="card">
                                        <div class="card-image">
                                            <img src="@/assets/images/guide/img_card_2_2.jpg" alt="" />
                                        </div>
                                        <div class="card-body">
                                            <p class="card-text">
                                                비즈니스 애널리스트는 몇 분 안에 서버리스, Pixel-Perfect 대시보드를 원활하게 만듭니다. 또한, 클라이언트
                                                소프트웨어나 서버 인프라 없이도 Amazon S3에 있는 페타바이트 규모의 데이터에 안전하게 연결하여 Amazon Athena를
                                                사용해 쿼리를 수행하고 Amazon QuickSight의 수만 명의 사용자와 공유할 수 있습니다.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <!-- card-item -->
                                <li class="card-item">
                                    <div class="card">
                                        <div class="card-image">
                                            <img src="@/assets/images/guide/img_card_2_3.jpg" alt="" />
                                        </div>
                                        <div class="card-body">
                                            <p class="card-text">
                                                개발자는 강력한 AWS API를 사용한 앱에서 수십만 명의 사용자에게 임베디드 분석을 배포하고 그 크기를 조정할 수
                                                있습니다. 웹, 모바일, 이메일 또는 임베디드 애플리케이션에서 데이터 시각화 자료와 인사이트를 조직의 모든 사용자와
                                                공유할 수 있습니다.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                                <!-- card-item -->
                                <li class="card-item">
                                    <div class="card">
                                        <div class="card-image">
                                            <img src="@/assets/images/guide/img_card_2_4.jpg" alt="" />
                                        </div>
                                        <div class="card-body">
                                            <p class="card-text">
                                                워크로드에 맞게 자동으로 크기 조정되기 때문에 관리자는 일관된 성능을 제공할 수 있습니다. QuickSight는 2주마다
                                                업데이트를 제공하여 기존의 BI 솔루션에서 나타나는 가동 중지, 버전 충돌 또는 호환성 문제 없이 모든 사용자가 최신
                                                기능을 갖출 수 있도록 보장합니다. 또한 세션별 요금제를 제공하는 최초의 BI 서비스로 대규모 배포 시 효율적입니다.
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <!-- //page-body -->
    </main>
</template>

<script>
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
export default {
    name: 'Visualization',
    components: {CommonBreadcrumb},
};
</script>

<style scoped></style>
